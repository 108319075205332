<template>
  <v-btn
    @click="copyFileUrl"
    color="green"
    class="white--text"
    small
  >
    {{'action.copy_link'| t}}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import downloadjs from 'downloadjs'
import listActionMixins from '@/components/list/listDataAction/listDataAction.js'
export default {
  mixins: [listActionMixins],
  data: () => ({
    downloadLoading: false,
  }),
  methods: {
    async copyFileUrl() {
      try {
        await this.$copyText(window.eagleLodash.cloneDeep(this.fileUrl))
        this.$snotify.success(
            this.fileUrl,
            this.$t('copy.successfully')
        )
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    file() {
      return this.row
    },
    fileBaseUrl() {
      return this.$store.getters['base/application'].fileBaseUrl
    },
    fileUrl() {
      if(!this.file) return null
      const path = window.eagleLodash.cloneDeep(this.file.path).substring(1)
      return `${this.fileBaseUrl}${path}`
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>